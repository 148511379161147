<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    routeback="/fornecedor/comercial/cupom-desconto"
  >
    <div style="padding: 20px;text-align: center;">
      <span style="opacity: 0.5;"
        >Configure as mensagens enviadas pelo compartilhamento na página de
      </span>
      <a href="/fornecedor/configuracao/tema" target="_blank">temas</a>
      <br />
      <a
        href="https://atendimento.lifeapps.com.br/kb/article/379812/como-configurar-cupom-de-recomendacao?ticketId=&q=como%20configurar%20cupom%20de%20recomenda%C3%A7%C3%A3o"
        style="font-size: 14px;"
        class="ajuda"
        target="_new"
      >
        Ajuda com a configuração de cupom recomendação
      </a>
    </div>

    <FormSelect
      label="Tipo"
      v-model="form.tipoCupom"
      :options="optionsTipoCupom"
      :validation="$v.form.tipoCupom"
      @change="cleanDesconto"
    />
    <FormField
      v-if="form.valorDesconto"
      label="Desconto"
      v-model="form.valorDesconto"
      :validation="$v.form.valorDesconto"
      :money="form.tipoCupom === '%' ? numberMask : moneyMask"
    />
    <FormField
      v-if="!form.valorDesconto"
      label="Desconto"
      v-model="form.valorDesconto"
      :validation="$v.form.valorDesconto"
    />

    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import FormSelect from '@/components/form/FormSelect'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import SidePopup from '@/components/SidePopup'
import {
  CUPOM_RECOMENDACAO_UPDATE,
  CUPOM_RECOMENDACAO_LOAD
} from '@/store/actions/cupom'
import { required } from 'vuelidate/lib/validators'
import mixinUtils from '@/mixins/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'CupomRecomendacaoForm',
  mixins: [mixinUtils],
  components: {
    SidePopup,
    FormSelect,
    FormField,
    FormButton
  },
  validations: {
    form: {
      tipoCupom: { required },
      valorDesconto: { required }
    }
  },
  data: () => ({
    form: null,
    title: 'Cupom de recomendação',
    tiposCupom: ['%', 'R$'],
    TIPO_CUPOM_VALUE: {
      '%': '%',
      R$: '$',
      $: 'R$'
    },
    numberMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 0,
      masked: false
    },
    moneyMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 3,
      masked: false
    },
    enableEditLimit: false
  }),
  created() {
    this.form = this.cleanForm
    this.getCupomRecomendacao()
  },
  computed: {
    ...mapGetters(['getFornecedorTag']),
    optionsTipoCupom() {
      return this.tiposCupom.map(t => {
        return { text: t, value: t }
      })
    },
    cleanForm() {
      return {
        valorDesconto: '',
        tipoCupom: this.tiposCupom[0]
      }
    }
  },
  methods: {
    cleanDesconto() {
      this.form.valorDesconto = ''
    },
    prepareData() {
      let valorDesconto = this.form.valorDesconto
        ? this.form.valorDesconto.replace(/[%R$]/g, '')
        : this.form.valorDesconto

      if (this.form.tipoCupom === 'R$') {
        valorDesconto = valorDesconto
          .replace('.', ' ')
          .replace(',', '.')
          .replace(' ', '')
      }

      return {
        tipoCupom: this.TIPO_CUPOM_VALUE[this.form.tipoCupom],
        valorDesconto
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const payload = this.prepareData()
      this.$store.dispatch(CUPOM_RECOMENDACAO_UPDATE, payload).then(result => {
        if (!result)
          return this.$vueOnToast.pop(
            'success',
            'Erro ao salvar cupom de recomendação'
          )
        const msg = 'Cupom de recomendação salvo com sucesso'
        this.$vueOnToast.pop('success', msg)
        this.$router.replace('/fornecedor/comercial/cupom-desconto')
        this.$emit('close')
      })
    },
    getCupomRecomendacao() {
      this.$store
        .dispatch(CUPOM_RECOMENDACAO_LOAD)
        .then((cupomRecomendacao = {}) => {
          const { valorDesconto, tipoCupom } = cupomRecomendacao
          this.form = {
            valorDesconto: valorDesconto || null,
            tipoCupom: this.TIPO_CUPOM_VALUE[tipoCupom] || this.tiposCupom[0]
          }
        })
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>
