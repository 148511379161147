<script>
export default {
  methods: {
    prepareNumberDecimal(number) {
      return number ? number.replace(',', '.').replace(/\s/g, '') : null
    },
    prepareNumberDecimalToString(number, decimals = 2) {
      return number
        ? `${(Number(number) || 0).toFixed(decimals)}`.replace('.', ',')
        : null
    },
    removeNonAlfanumeric(string = '', fallback = '', acceptSpace = false) {
      if (acceptSpace) {
        return string.replace(/[^a-zA-Z0-9\s]/g, fallback)
      }
      return string.replace(/\W/g, fallback)
    }
  }
}
</script>
